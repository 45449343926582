<template>
  <div class="menu-wrapper">
    <template v-for="item in menu">
      <el-menu-item
          v-if="validateNull(item[childrenKey])"
          v-show="validRoles(item)"
          :index="item[pathKey]"
          @click="open(item)"
          :key="item[labelKey]"
          :class="{'is-active':validActive(item)}">
        <i :class="item[iconKey]"/>
        <span
            slot="title"
            :alt="item[pathKey]"
        >{{ item[labelKey] }}</span>
      </el-menu-item>
      <el-submenu v-else v-show="validRoles(item)" :index="item[pathKey]" :key="item[labelKey]">
        <template slot="title">
          <i :class="item[iconKey]"/> <span
            slot="title"
            :class="{'el-menu--display':collapse && first}">{{ item[labelKey] }}</span>
        </template>
        <template v-for="(child,cIndex) in item[childrenKey]">
          <el-menu-item
              :index="child[pathKey]"
              @click="open(child)"
              :class="{'is-active':validActive(child)}"
              v-if="validateNull(child[childrenKey]) && validRoles(child)"
              :key="child[labelKey]">
            <i :class="child[iconKey]"/>
            <span slot="title">{{ child[labelKey] }}</span>
          </el-menu-item>
          <sidebar-item v-else :menu="[child]" :key="cIndex" :props="props" :collapse="collapse"/>
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validateNull } from '@/util/validate'
import { newWin } from '@/util/index'
import config from './config.js'

export default {
  name: 'SidebarItem',
  data () {
    return {
      config: config
    }
  },
  props: {
    menu: {
      type: Array
    },
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    first: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['roles']),
    labelKey () {
      return this.props.label || this.config.propsDefault.label
    },
    pathKey () {
      return this.props.path || this.config.propsDefault.path
    },
    iconKey () {
      return this.props.icon || this.config.propsDefault.icon
    },
    childrenKey () {
      return this.props.children || this.config.propsDefault.children
    }
  },
  methods: {
    validActive (item) {
      return (item.children || []).some(ele =>
        this.$route.path.includes(ele.path)
      )
    },
    validRoles (item) {
      if (!item.display) {
        return false
      }
      item.meta = item.meta || {}
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true
    },
    validateNull (val) {
      return validateNull(val)
    },
    open (item) {
      if (item.open && item.path && item.path.startsWith('http')) {
        newWin(item.path)
        return false
      }
      this.$router.$vueRouter.group = item.group
      this.$router.$vueRouter.meta = item.meta
      this.$router.push({
        path: this.$router.$vueRouter.getPath({
          name: item[this.labelKey],
          src: item[this.pathKey]
        }),
        query: item.query
      })
    }
  }
}
</script>

