const title = '中汇数农智慧农贸管理平台'

// 公众号appid
const appid = 'wx3304b9a187a5981d'

// 同时展示两级菜单
const useSecMenu = true

// 配置菜单的属性
const menuDefault = {
  iconDefault: 'iconfont icon-caidan',
  props: {
    label: 'name',
    path: 'path',
    icon: 'icon',
    children: 'children'
  }
}
const storageKey = {
  userInfo: 'userInfo',
  permission: 'permission',
  menu: 'menu',
  token: 'token'
}
const flowDesignUrl = 'http://localhost:7081'

const config = {
  development: {
    baseUrl: 'http://172.16.2.23:7702/admin'
  },
  production: {
    baseUrl: 'https://scale.zcondigit.com/admin'
  },
  sh: {
    baseUrl: 'http://sh.zcondigit.com:7788/admin'
  }
}
const env = process.env

const baseUrl = config[env.NODE_ENV].baseUrl

const flowUrl = baseUrl + '/flow/process/diagram-view?processInstanceId='

export {
  flowDesignUrl,
  flowUrl,
  title,
  appid,
  useSecMenu,
  baseUrl,
  menuDefault,
  storageKey
}
