<template>
  <div class="vue-top">
    <div class="top-bar__left">
      <div
          v-if="!useSecMenu"
          class="vue-breadcrumb"
          :class="[{ 'vue-breadcrumb--active': isCollapse }]">
        <i
            class="icon-navicon"
            @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__right">
      <el-button class="name" type="text" @click="goInfo"></el-button>
      <span>{{ userInfo.username }}</span>
      <span><i class="icon-tuichu" @click="logout"></i></span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { validateNull } from '@/util/validate'
import { useSecMenu } from '@/config'

export default {
  components: {},
  name: 'Top',
  data () {
    return {
      useSecMenu: useSecMenu
    }
  },
  filters: {},
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({}),
    ...mapGetters([
      'userInfo',
      'isCollapse'
    ])
  },
  methods: {
    validateNull (val) {
      return validateNull(val)
    },
    setCollapse () {
      this.$store.commit('SET_COLLAPSE')
    },
    goInfo () {
      this.$router.push('/')
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar__right {
  span {
    font-size: 15px;
    margin-right: 5px;
  }

}
</style>
