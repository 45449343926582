import { get, post } from '@/util/http'

export const loginByUsername = (dto) =>
  post('/anon/admin/login', dto)

export const sendSms = (mobile) => get('/anon/sms', { mobile })

export const passwordSms = (dto) =>
  post('/anon/passwordSms', dto)

export const getUserInfo = () => get('/user/getUserInfo')

export const refreshToken = () => post('/user/refesh')

export const getTopMenu = () => get('/user/getTopMenu')

export const sendLogs = (list) => post('/user/logout', list)

