export const getObjType = obj => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}

/**
 * 对象深拷贝
 */
export const deepClone = data => {
  const type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

export function delParam (paramKey) {
  let url = window.location.href // 页面url
  const urlParam = window.location.search.substr(1) // 页面参数
  const beforeUrl = url.substr(0, url.indexOf('?')) // 页面主地址（参数之前地址）
  let nextUrl = ''

  const arr = []
  if (urlParam !== '') {
    const urlParamArr = urlParam.split('&') // 将参数按照&符分成数组
    for (let i = 0; i < urlParamArr.length; i++) {
      const paramArr = urlParamArr[i].split('=') // 将参数键，值拆开
      // 如果键雨要删除的不一致，则加入到参数中
      if (paramArr[0] !== paramKey) {
        arr.push(urlParamArr[i])
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = '?' + arr.join('&')
  }
  url = beforeUrl + nextUrl
  return url
}

/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  const left = ((width / 2) - (w / 2)) + dualScreenLeft
  const top = ((height / 2) - (h / 2)) + dualScreenTop
  const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}

export const newWin = (url) => {
  const id = 'newWin'
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', id)
  // 防止反复添加
  if (!document.getElementById(id)) {
    document.body.appendChild(a)
  }
  a.click()
}

export function isNotNull (val) {
  return val !== 'null' && val !== null &&
        val !== 'undefined' &&
        val !== undefined &&
        val !== ''
}

export const formatArea = (num, precision = 2) => {
  if (precision < 0 || precision > 20) precision = 2
  if (parseFloat(num).toString() !== 'NaN') {
    // num = parseFloat((num + "").replace(/[^\d\.-]/g, "")).toFixed(precision) + ""
    num = parseFloat((num + '').replace(/[^\d\.-]/g, '')) + ''
    const left = num.split('.')[0].split('').reverse()
    let right = num.split('.')[1]
    right = right ? right + '0'.repeat(20) : '0'.repeat(20) // 禁止小数位进位直接截取
    right = right.substring(0, precision)

    let total = ''
    for (let i = 0; i < left.length; i++) {
      total += left[i] + ((i + 1) % 3 === 0 && (i + 1) !== left.length ? ',' : '')
    }

    const leftPart = total.split('').reverse().join('').replace(/-,/, '-')
    if (right) {
      return leftPart + '.' + right
    }
    return leftPart
  }
  return ''
}

export const parseArea = (str) => {
  return parseFloat(str.replace(/[^\d\.-]/g, ''))
}

// 将rgb颜色转成hex
export function colorRGB2Hex (color) {
  if (color.indexOf(',') === -1) {
    return ''
  }
  const rgb = color.split(',')
  const r = parseInt(rgb[0].split('(')[1])
  const g = parseInt(rgb[1])
  const b = parseInt(rgb[2].split(')')[0])

  const hex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  return hex
}

// 将hex颜色转成rgb
export function hexToRgba (hex, opacity) {
  const RGBA = 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')'
  return {
    red: parseInt('0x' + hex.slice(1, 3)),
    green: parseInt('0x' + hex.slice(3, 5)),
    blue: parseInt('0x' + hex.slice(5, 7)),
    rgba: RGBA
  }
}
