import Layout from '@/page/index/'

export default [
  {
    path: '/',
    component: Layout,
    children: [{
      path: 'admin',
      name: '管理员首页',
      component: () => import('@/views/home/admin')
    }, {
      path: 'ops',
      name: '运营首页',
      component: () => import('@/views/home/ops')
    }, {
      path: 'merchant',
      name: '商户首页',
      component: () => import('@/views/home/merchant')
    }]
  },
  {
    path: '/scale/merchant',
    name: '秤端商户首页',
    component: () => import('@/views/home/merchant'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/scale/order/list',
    name: '订单列表',
    component: () => import('@/views/stat/order-list')
  },
  {
    path: '/scale/order/stat',
    name: '订单统计',
    component: () => import('@/views/stat/order-stat')
  }
]
