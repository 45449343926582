<template>
  <div class="vue-content" :class="{'vue--collapse':isCollapse}">
    <div class="vue-header">
      <!-- 顶部导航栏 -->
      <top/>
    </div>
    <div class="vue-layout">
      <div>
        <!-- 左侧导航栏 -->
        <sec-bar v-if="useSecMenu"/>
        <sidebar v-else/>
      </div>
      <div class="vue-main">
        <div style="display:flex;height: 100%;">
          <!-- 主体视图层 -->
          <el-scrollbar style="height:100%;flex:1">
            <keep-alive>
              <router-view class="vue-view" v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <router-view class="vue-view" v-if="!$route.meta.keepAlive"/>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="vue-shade"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import top from './top/'
import sidebar from './sidebar/'
import secBar from './secbar/'
import { useSecMenu } from '@/config'

export default {
  components: {
    top,
    sidebar,
    secBar
  },
  name: 'Index',
  data () {
    return {
      useSecMenu: useSecMenu
    }
  },
  created () {
    // 实时检测刷新token
  },
  mounted () {
  },
  computed: mapGetters(['isCollapse']),
  props: [],
  methods: {}
}
</script>
